import Section from "../../../../components/shared/Section"
import React from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import * as styles from "./styles.module.scss"
import { Link } from "gatsby"

const BlogCards = ({ cardData }) => {
	return (
		<Section>
			<Container>
				<Row className="mb-5 pb-5">
					{cardData.map((cards) => (
						<Col md={4} className="mt-5">
							<Card className={styles.cards}>
								<Card.Img variant="top" src={cards.image} />
								<Card.Body>
									<p className="mt-3">{cards.date}</p>
									<Link to="/blogDetail" className="text-decoration-none text-dark">
									<h5>{cards.title}</h5>
									</Link>
								</Card.Body>
							</Card>
						</Col>
					))}
				</Row>
			</Container>
		</Section>
	)
}

export default BlogCards
