import React from "react"
import card_img from "../assets/images/blog_card.png"

export const content = {
	blogcards: [
		{
			image: card_img,
			date: "2022-05-22",
			title: "Top 4 trends to watch on 2022",
		},
		{
			image: card_img,
			date: "2022-05-22",
			title: "Top 4 trends to watch on 2022",
		},
		{
			image: card_img,
			date: "2022-05-22",
			title: "Top 4 trends to watch on 2022",
		},
		{
			image: card_img,
			date: "2022-05-22",
			title: "Top 4 trends to watch on 2022",
		},
		{
			image: card_img,
			date: "2022-05-22",
			title: "Top 4 trends to watch on 2022",
		},
		{
			image: card_img,
			date: "2022-05-22",
			title: "Top 4 trends to watch on 2022",
		},
	],
}
