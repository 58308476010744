import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Section from "../../../../components/shared/Section"
import * as styles from "./styles.module.scss"

const HeroBlog = () => {
	return (
		<Section className={styles.blogBg}>
			<Container>
				<Row>
					<Col md={6}></Col>
				</Row>
			</Container>
		</Section>
	)
}

export default HeroBlog
