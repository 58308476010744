import HeroBlog from "../components/pages/blog/HeroBlog"
import React from "react"
import { Main } from "../components/layout"
import BlogCards from "../components/pages/blog/BlogCards"
import { content } from "../content/blog"

const BLog = () => {
	return (
		<>
			<Main>
				<HeroBlog />
				<BlogCards cardData={content.blogcards} />
			</Main>
		</>
	)
}

export default BLog
